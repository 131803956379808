import "./App.css";
import ReactPlayer from "react-player";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  ExternalRedirect,
} from "react-router-dom";
import Homepage from "./Homepage";
import Redirect from "./Redirect";
import Forwarder from "./Forwarder";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/rs" element={<Forwarder/>}/>
      </Routes>
    </Router>
  );
}

export default App;
