import logo from './logo.svg';
import './App.css';
import ReactPlayer from 'react-player';

function Homepage() {
  return (
    <div className="App">
      <header className="App-header">
        
        <ReactPlayer
          playing="true"
          url="videos/BrandRevealShort.mp4"
          height="75%"
          width="75%"
          volume="0"
          onPause={() => {
            setTimeout("window.location='https://justspark.com'", 2000);
          }}
        />
        <p>
          Graficom wordt Justspark
          <br />
          
        </p>
      </header>
    </div>
  );
}

export default Homepage;


