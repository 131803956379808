import logo from "./logo.svg";
import "./App.css";
import ReactPlayer from "react-player";

function Forwarder() {
  return (
    <div className="App">
      <header className="App-header">
        <script>
          () {setTimeout("window.location='https://justspark.com/support'", 1000)}
        </script>
      </header>
    </div>
  );
}

export default Forwarder;
